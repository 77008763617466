.errorContainer{
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 30px 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.errorContent{
    max-width: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorContent h2{
    font-weight: 700;
    font-size: 1.8rem;
}

.errorContent p{
    font-size: 14px;
    color: var(--color-dark-grey);
    font-weight: 500;
}

.errorContent .button{
    padding: 10px 25px;
}
